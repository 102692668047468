import { css } from '@emotion/react'

const style = {
  tabList: css`
    .nav {
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    .nav-item {
      margin: 0;
      min-width: 120px;
      font-size: inherit;
      line-height: inherit;
    }
  `,
}

export default style
