import { navigate } from 'gatsby'
import { I18nContext, localizePath } from 'gatsby-plugin-i18n'
import { useContext } from 'react'
import NavBar from 'swp-components/lib/components/navbar/navbar'

import style from './style'

const HeaderCitrine = ({ menu, services, ...props }) => {
  let { locale, defaultLocale, originalPath } = useContext(I18nContext)

  let handleAction = (action, payload) => {
    if (action === 'change-locale') {
      navigate(localizePath(originalPath, payload.locale, defaultLocale))
    }
  }

  return (
    <>
      <NavBar menu={menu} onAction={handleAction} />
      <div css={style.divHeight}></div>
    </>
  )
}

export default HeaderCitrine
