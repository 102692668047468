import { css } from '@emotion/react'

const style = {
  divHeight: css`
    height: 40px;
    @media (min-width: 1024px) {
      height: 80px;
    }
  `,
}

export default style
