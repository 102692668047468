import usePageDataLayer from './use-page-data-layer'

const handlePageMessage = async (
  { selector, context, trigger },
  pushMessage
) => {
  if (/^TopBanner\/Carousel.banner#\d+$/i.test(selector)) {
    /* Prepay */
    if (trigger === 'click' && selector === 'TopBanner/Carousel.banner#65') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/m8wyCPDPnJcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: '5G banner',
        content_category: '5G Package',
      })
    }
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: `campaigns-${context.pageGroup}`,
      ecommerce: {
        items: [
          {
            item_id: context.banner.id.toString(),
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 'none',
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: `campaigns-${context.pageGroup}_top_banner`,
            item_list_name: 'top_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: 'none',
            promotion_id: 'none',
            creative_name: context.banner.name,
            creative_slot: 'top_banner',
            location_id: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
          },
        ],
      },
    })
  }

  if (/^HighlightBanner\/Grid.banner#\d+$/i.test(selector)) {
    /* Prepay */
    if (trigger === 'click' && selector === 'HighlightBanner/Grid.banner#93') {
      window.fbq('track', 'ViewContent', {
        content_name: '5G 30GB',
        content_category: '5G Package',
      })
    }
    if (trigger === 'click' && selector === 'HighlightBanner/Grid.banner#94') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/joyNCPbPnJcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: '5G 80GB',
        content_category: '5G Package',
      })
    }
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: `campaigns-${context.pageGroup}`,
      ecommerce: {
        items: [
          {
            item_id: context.banner.id.toString(),
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 'none',
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: `campaigns-${context.pageGroup}_top_banner`,
            item_list_name: 'top_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: 'none',
            promotion_id: 'none',
            creative_name: context.banner.name,
            creative_slot: 'top_banner',
            location_id: context.topBanners.findIndex(
              banner => banner.id === context.banner.id
            ),
          },
        ],
      },
    })
  }

  if (selector === 'BottomBanner/Banner') {
    pushMessage({
      event: { click: 'select_promotion', view: 'view_promotion' }[trigger],
      page_group: `campaigns-${context.pageGroup}`,
      ecommerce: {
        items: [
          {
            item_id: context.banner.id.toString(),
            item_name: 'none',
            coupon: 'none',
            currency: 'THB',
            discount: 'none',
            index: 'none',
            item_brand: 'none',
            item_category: 'none',
            item_category2: 'none',
            item_category3: 'none',
            item_category4: 'none',
            item_category5: 'none',
            item_list_id: `campaigns-${context.pageGroup}_top_banner`,
            item_list_name: 'top_banner',
            item_variant: 'none',
            price: 'none',
            quantity: 1,
            promotion_name: 'none',
            promotion_id: 'none',
            creative_name: context.banner.name,
            creative_slot: 'top_banner',
            location_id: context.banner.id,
          },
        ],
      },
    })
  }

  if (/^ShortcutMenu\/\[MenuItem\]#\d+$/i.test(selector)) {
    /* Prepay */
    if (trigger === 'click' && selector === 'ShortcutMenu/[MenuItem]#180') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/REyRCPPPnJcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: 'Sim prepaid',
        content_category: '5G Package',
      })
    }
    /* Postpaid */
    if (trigger === 'click' && selector === 'ShortcutMenu/[MenuItem]#181') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/zKdACIvakpcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: 'NewNumber_ClickButton',
        content_category: 'Commercial Postpaid',
      })
    }
    if (trigger === 'click' && selector === 'ShortcutMenu/[MenuItem]#182') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/ZYF7CI7akpcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: 'MNP_ClickButton',
        content_category: 'Commercial Postpaid',
      })
    }
    if (trigger === 'click' && selector === 'ShortcutMenu/[MenuItem]#183') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/se_eCJHakpcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: 'Pre2Post_ClickButton',
        content_category: 'Commercial Postpaid',
      })
    }
    if (trigger === 'click' && selector === 'ShortcutMenu/[MenuItem]#184') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-859838923/_PMVCJTakpcYEMuzgJoD',
      })
      window.fbq('track', 'ViewContent', {
        content_name: 'LuckyNumber_ClickButton',
        content_category: 'Commercial Postpaid',
      })
    }
    pushMessage({
      event: 'shortcut_menu',
      page_group: `campaigns-${context.pageGroup}`,
      shortcut_menu: context.item.name,
    })
  }

  if (selector === 'BenefitList/RightArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'right_arrow',
    })
  }
  if (selector === 'BenefitList/LeftArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'left_arrow',
    })
  }

  if (selector === 'ServiceInfoList/RightArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'right_arrow',
    })
  }
  if (selector === 'ServiceInfoList/LeftArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'left_arrow',
    })
  }

  if (/^DeviceList\/Tabs.tab#\d+$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: `campaigns-${context.pageGroup}`,
      button: context.tab.title,
      section_name: context.section.name,
    })
  }

  if (/^HowTo\/StepImage#\d+$/i.test(selector)) {
    const regex = /&nbsp;/gm
    const detail = context.detail.replaceAll(regex, ' ')
    pushMessage({
      event: 'interaction_menu',
      page_group: `campaigns-${context.pageGroup}`,
      content_name: detail,
      section_name: context.section.name,
    })
  }

  if (selector === 'HowTo/RightArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'right_arrow',
    })
  }
  if (selector === 'HowTo/LeftArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'left_arrow',
    })
  }

  if (/^HowTo\/Tabs.tab#\d+$/i.test(selector)) {
    pushMessage({
      event: 'call_to_action',
      page_group: `campaigns-${context.pageGroup}`,
      button: context.tab.title,
      section_name: context.section.name,
    })
  }

  if (/^NewHowTo\/StepNumber#\d+$/i.test(selector)) {
    const regex = /&nbsp;/gm
    const detail = context.detail.replaceAll(regex, ' ')
    pushMessage({
      event: 'interaction_menu',
      page_group: `campaigns-${context.pageGroup}`,
      content_name: detail,
      section_name: context.section.name,
    })
  }

  if (/^NewHowTo\/\[Card.button\]#\d+$/i.test(selector)) {
    pushMessage({
      event: 'carousel_content',
      page_group: `campaigns-${context.pageGroup}`,
      content_name: context.privilege.title,
      section_name: context.section.name,
      carousel_action: context.privilege.url != null ? 'outbound' : 'drop_down',
    })
  }

  if (/^NewHowTo\/MobileToggle#\d+$/i.test(selector)) {
    pushMessage({
      event: 'carousel_content',
      page_group: `campaigns-${context.pageGroup}`,
      content_name: context.title,
      section_name: context.section.name,
      carousel_action: context.url != null ? 'outbound' : 'drop_down',
    })
  }

  if (selector === 'NewHowTo/RightArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'right_arrow',
    })
  }
  if (selector === 'NewHowTo/LeftArrow') {
    pushMessage({
      event: 'arrow',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      arrow_type: 'left_arrow',
    })
  }

  if (/^FAQ\/FaqList#\d+$/i.test(selector)) {
    pushMessage({
      event: 'select_content',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      content_name: context.detail.name,
      content_type: 'FAQ',
    })
  }

  if (selector === 'EndCampaign') {
    pushMessage({
      event: 'select_content',
      page_group: `end-campaigns`,
      section_name: 'end_campaign',
      content_type: 'END_Campaign',
    })
  }

  if (selector === 'VideoCampaign') {
    pushMessage({
      event: 'select_content',
      page_group: `campaigns-${context.pageGroup}`,
      section_name: context.section.name,
      content_name: context.detail.name,
      content_type: 'VIDEO',
    })
  }
}

const useHomePageDataLayer = () => {
  let { createHandler, createScopeProps } = usePageDataLayer()
  let handleMessage = createHandler(handlePageMessage)
  return { handleMessage, createScopeProps }
}

export default useHomePageDataLayer
