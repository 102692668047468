import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { SimpleCard } from 'swp-components/lib/components/faq'

import useColumns from '@/hooks/use-columns'

import style from './style'

const ServiceInfoGrid = ({ topics, ...props }) => {
  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 1 },
    { value: breakpoints.md, columns: 2 },
  ])

  let centered = topics.length < columns

  return (
    <Container {...props}>
      <div css={style.wrapper(centered)}>
        {topics.map(topic => (
          <div
            key={topic.id}
            css={style.box}
            style={{ width: `${100 / columns}%` }}
          >
            <SimpleCard css={style.card} topic={topic} />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default ServiceInfoGrid
