import { kebabCase } from 'lodash-es'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Background, Heading } from 'swp-components/lib/components/section'

import style from './style'

const Section = ({ dense, section, children, tag = 'h2' }) => {
  if (section.imageBackground) {
    return (
      <Background
        css={style.box}
        className={dense ? 'py-4' : 'py-5'}
        image={section.imageBackground ? section.imageBackground.url : ''}
        circle={section.circle}
      >
        {!dense && (
          <Container>
            <Heading
              color={kebabCase(section.headerColor)}
              variant={kebabCase(section.headerVariant)}
              tag={tag}
            >
              {section.name}
            </Heading>
            <Heading className="mt-3 mb-4" level="2">
              {section.detail}
            </Heading>
          </Container>
        )}
        {children}
      </Background>
    )
  }

  if (section.backgroundColor && section.backgroundColor !== 'white') {
    return (
      <Background
        css={style.box}
        className={dense ? 'py-4' : 'py-5'}
        color={kebabCase(section.backgroundColor)}
        circle={section.circle}
      >
        {!dense && (
          <Container>
            <Heading
              color={kebabCase(section.headerColor)}
              variant={kebabCase(section.headerVariant)}
              tag={tag}
            >
              {section.name}
            </Heading>
            <Heading className="mt-3 mb-4" level="2">
              {section.detail}
            </Heading>
          </Container>
        )}
        {children}
      </Background>
    )
  }

  if (
    section.backgroundColor1 &&
    section.backgroundColor2 &&
    section.backgroundColor3
  ) {
    return (
      <div
        className={dense ? 'py-4' : 'py-5'}
        style={{
          background: `linear-gradient(${section.backgroundColor1},${section.backgroundColor2},${section.backgroundColor3})`,
        }}
      >
        {!dense && (
          <Container>
            <Heading
              color={kebabCase(section.headerColor)}
              variant={kebabCase(section.headerVariant)}
              tag={tag}
            >
              {section.name}
            </Heading>
            <Heading className="mt-3 mb-4" level="2">
              {section.detail}
            </Heading>
          </Container>
        )}
        {children}
      </div>
    )
  }

  return (
    <div className="my-5" css={style.box}>
      <Container>
        <Heading
          color={kebabCase(section.headerColor)}
          variant={kebabCase(section.headerVariant)}
          tag={tag}
        >
          {section.name}
        </Heading>
        <Heading className="mt-3 mb-4" level="2">
          {section.detail}
        </Heading>
      </Container>
      {children}
    </div>
  )
}

export default Section
