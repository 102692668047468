import { useContext, useState } from 'react'
import {
  Accordion,
  AccordionContext,
  Card as BCard,
  Col,
  Container,
  Fade,
  Row,
  useAccordionButton,
} from 'react-bootstrap'
import { DataLayer } from 'swp-components/lib/components/common'
import { Image } from 'swp-components/lib/components/common'
import {
  Card,
  Columns,
  HowToStepperNumber,
} from 'swp-components/lib/components/esim'

import SectionHeading from '@/components/section-heading'
import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

function CustomToggle({ eventKey, isClickable, detail, callback }) {
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey)
  })

  const isCurrentEventKey = activeEventKey === eventKey

  if (isClickable === false) {
    return (
      <BCard.Header>
        <Row>
          <Col xs={4}>
            <Image image={detail.image} />
          </Col>
          <Col xs={8}>{detail.name}</Col>
        </Row>
      </BCard.Header>
    )
  }

  return (
    <BCard.Header
      style={{
        border: isCurrentEventKey
          ? '1px solid #FAB416'
          : '1px solid rgba(0, 0, 0, 0.125)',
        boxShadow: isCurrentEventKey
          ? '0px 4px 8px rgba(0, 0, 0, 0.15)'
          : 'none',
        maxHeight: '200px',
      }}
    >
      <DataLayer
        selector={`MobileToggle#${detail.id}`}
        triggers={['click']}
        context={detail}
        key={detail.id}
        render={ref => (
          <Row
            ref={ref}
            onClick={decoratedOnClick}
            style={{ maxHeight: '180px' }}
          >
            <Col xs={4} style={{ maxHeight: '180px' }}>
              <Image image={detail.image} />
            </Col>
            <Col xs={6} style={{ maxHeight: '180px' }} css={style.mobileTitle}>
              {detail.name}
            </Col>
            <Col xs={2} style={{ maxHeight: '180px' }}>
              {isCurrentEventKey === true && (
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: '150%',
                    padding: '100% 0',
                    color: '#A6A6A6',
                    filter:
                      'invert(73%) sepia(0%) saturate(0%) hue-rotate(284deg) brightness(91%) contrast(91%)',
                  }}
                >
                  <path d="m5.47 9.53 6 6a.75.75 0 0 0 1.06 0l6-6a.75.75 0 0 0-1.06-1.06L12 13.94 6.53 8.47a.75.75 0 0 0-1.06 1.06Z" />
                </svg>
              )}
              {isCurrentEventKey === false && (
                <svg
                  viewBox="0 0 24 24"
                  style={{
                    width: '150%',
                    padding: '100% 0',
                    color: '#A6A6A6',
                    filter:
                      'invert(73%) sepia(0%) saturate(0%) hue-rotate(284deg) brightness(91%) contrast(91%)',
                  }}
                >
                  <path d="M6.53 15.53 12 10.061l5.47 5.469a.749.749 0 1 0 1.06-1.06l-6-6a.749.749 0 0 0-1.06 0l-6 6a.749.749 0 1 0 1.06 1.06Z" />
                </svg>
              )}
            </Col>
          </Row>
        )}
      />
    </BCard.Header>
  )
}

const EsimNewHowTo = ({ howToList, locale, ...props }) => {
  let [selectedPrivilege, setSelectedPrivilege] = useState(null)
  let [detailShow, setDetailShow] = useState(false)
  let [cardID, setCardID] = useState(0)
  let sliderOptions = generateSliderOptions(240)

  let handleCardAction = privilege => {
    return action => {
      if (action === 'show-detail') {
        setSelectedPrivilege(privilege)
        setDetailShow(true)
        setCardID(privilege.id)
      }
    }
  }
  let findHighest = () => {
    const allWithClass = Array.from(
      document.getElementsByClassName('mobileColumn')
    )
    var maxHeight = 0
    allWithClass.forEach((a, b) => {
      if (maxHeight === 0 || maxHeight < a.offsetHeight) {
        maxHeight = a.offsetHeight
      }
    })
    allWithClass.forEach((a, b) => {
      if (a.offsetHeight !== 0) {
        a.style.height = `${maxHeight}px`
      }
    })
    maxHeight = 0
  }

  let renderContent = priv => {
    if (priv.howToGroup != null && priv.howToGroup.cardDetails.length > 0) {
      return (
        <>
          {priv.howToGroup.title !== '' && (
            <SectionHeading className="my-2 justify-content-center" tag="h2">
              {priv.howToGroup.title}
            </SectionHeading>
          )}
          {priv.howToGroup.description !== '' && (
            <div
              className="ck-editor mb-1"
              dangerouslySetInnerHTML={{ __html: priv.howToGroup.description }}
            ></div>
          )}
          <Columns columns={2} items={priv.howToGroup.cardDetails} />
        </>
      )
    }
    if (priv.howToGroup != null && priv.howToGroup.howToProcess.length > 0) {
      return (
        <>
          {priv.howToGroup.title !== '' && (
            <SectionHeading className="my-2 justify-content-center" tag="h2">
              {priv.howToGroup.title}
            </SectionHeading>
          )}
          {priv.howToGroup.description !== '' && (
            <div
              className="ck-editor mb-1"
              dangerouslySetInnerHTML={{ __html: priv.howToGroup.description }}
            ></div>
          )}

          {priv.howToGroup.howToProcess.map((a, b) => (
            <>
              {a.title !== '' && (
                <h2
                  css={style.header}
                  className="my-2 justify-content-center"
                  dangerouslySetInnerHTML={{ __html: a.title }}
                ></h2>
              )}

              <HowToStepperNumber
                key={b}
                variant={a.isDetailLeftSide ? 'default' : 'reversed'}
                howTo={a}
              />
            </>
          ))}
        </>
      )
    }
  }

  howToList.map(a => {
    a.image = a.icon
    a.name = a.title
    if (
      a.howToGroup != null &&
      (a.howToGroup.howToProcess.length > 0 ||
        a.howToGroup.cardDetails.length > 0)
    ) {
      a.buttonName = locale === 'th' ? 'ดูรายละเอียด' : 'See more detail'
    }
    if (a.url) {
      a.buttonName = locale === 'th' ? 'ดูรายละเอียด' : 'See more detail'
    }
    if (a.howToGroup != null && a.howToGroup.howToProcess.length > 0) {
      a.howToGroup.howToProcess.map(b => {
        b.howToDetails = b.howToSteps
      })
    }
    if (a.howToGroup != null && a.howToGroup.cardDetails.length > 0) {
      a.howToGroup.cardDetails.map(c => {
        c.description = c.detail
        c.bulletImage = c.icon
      })
    }
  })
  return (
    <>
      <Container
        css={style.box}
        {...props}
        className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block position-relative"
      >
        <Row className="mb-3">
          {howToList.length > 4 && (
            <Slider options={sliderOptions} style={{ position: 'relative' }}>
              {howToList.map((a, b) => (
                <>
                  <Col
                    key={b}
                    xs={0}
                    sm={0}
                    md={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    className="my-3"
                  >
                    <div className="mx-2" style={{ height: '100%' }}>
                      {a.url && (
                        <a
                          className="nostyle"
                          href={a.url.url}
                          target={a.url.newTab ? '_blank' : ''}
                          rel="noreferrer"
                        >
                          <Card
                            css={style.card(a.id === cardID)}
                            privilege={a}
                            onAction={handleCardAction(a)}
                          />
                        </a>
                      )}
                      {a.url == null && (
                        <Card
                          css={style.card(a.id === cardID)}
                          privilege={a}
                          onAction={handleCardAction(a)}
                        />
                      )}
                    </div>
                  </Col>
                </>
              ))}
            </Slider>
          )}
          {howToList.length <= 4 && (
            <>
              {howToList.map((a, b) => (
                <Col
                  key={b}
                  xs={0}
                  sm={0}
                  md={3}
                  lg={3}
                  xl={3}
                  xxl={3}
                  className="my-3"
                >
                  {a.url && (
                    <a
                      className="nostyle"
                      href={a.url.url}
                      target={a.url.newTab ? '_blank' : ''}
                      rel="noreferrer"
                    >
                      <Card
                        css={style.card(a.id === cardID)}
                        privilege={a}
                        onAction={handleCardAction(a)}
                      />
                    </a>
                  )}
                  {a.url == null && (
                    <Card
                      css={style.card(a.id === cardID)}
                      privilege={a}
                      onAction={handleCardAction(a)}
                    />
                  )}
                </Col>
              ))}
            </>
          )}
        </Row>
        <Fade in={detailShow} timeout={1500}>
          <>
            {detailShow && (
              <Container css={style.box}>
                {renderContent(selectedPrivilege)}
              </Container>
            )}
          </>
        </Fade>
      </Container>
      <Container
        css={style.box}
        {...props}
        className="d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none position-relative"
      >
        <Row className="mb-5">
          <Accordion>
            {howToList.map((a, b) => (
              <BCard key={b} css={style.mobileCard} className="my-2">
                {a.url && (
                  <DataLayer
                    selector={`MobileToggle#${a.id}`}
                    triggers={['click']}
                    context={a}
                    key={a.id}
                    render={ref => (
                      <a
                        ref={ref}
                        className="nostyle"
                        href={a.url.url}
                        target={a.url.newTab ? '_blank' : ''}
                        rel="noreferrer"
                      >
                        <CustomToggle
                          eventKey={b}
                          isClickable={
                            a.howToGroup != null &&
                            (a.howToGroup.howToProcess.length === 0 ||
                              a.howToGroup.cardDetails.length === 0)
                          }
                          detail={a}
                        ></CustomToggle>
                      </a>
                    )}
                  />
                )}
                {a.url == null && (
                  <CustomToggle
                    eventKey={b}
                    isClickable={
                      a.howToGroup != null &&
                      (a.howToGroup.howToProcess.length > 0 ||
                        a.howToGroup.cardDetails.length > 0)
                    }
                    detail={a}
                  ></CustomToggle>
                )}

                <Accordion.Collapse eventKey={b} onEntered={findHighest}>
                  <BCard.Body css={style.box}>{renderContent(a)}</BCard.Body>
                </Accordion.Collapse>
              </BCard>
            ))}
          </Accordion>
        </Row>
      </Container>
    </>
  )
}

export default EsimNewHowTo
