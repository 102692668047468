import { Container } from 'swp-components/lib/components/bootstrap'
import { MenuItem } from 'swp-components/lib/components/shortcut-menu'

import Slider from '../slider'

const ShortcutMenu = ({ color, items, ...props }) => {
  items = items.slice(0, 7)

  return (
    <Container {...props}>
      <Slider>
        {items.map(item => (
          <MenuItem className="mx-2" key={item.id} color={color} item={item} />
        ))}
      </Slider>
    </Container>
  )
}

export default ShortcutMenu
