import { camelCase, flow, isBoolean, isPlainObject } from 'lodash-es'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Footer from 'swp-components/lib/components/footer'
import FooterSecondary from 'swp-components/lib/components/footer-secondary/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import CampaignZone from '@/components/campaign-zone'
import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import EndCampaign from '@/components/end-campaign'
import Gtm from '@/components/gtm'
import Header from '@/components/header'
import HeaderCitrine from '@/components/header-citrine'
import Newrelic from '@/components/newrelic-agent'
import Seo from '@/components/seo'
import useCampaignsPageDataLayer from '@/hooks/data-layers/use-campaigns'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

import { deepMapKeys, deepMapObjects, isValidUrl } from '../../strapi/util'

const isContentDataValid = value => {
  let { status, startDate, endDate } = value
  return [
    !isBoolean(status) || status,
    isNaN(Date.parse(startDate)) || Date.now() >= Date.parse(startDate),
    isNaN(Date.parse(endDate)) || Date.now() < Date.parse(endDate),
  ].every(valid => valid)
}

const filterContentData = content => {
  return deepMapObjects(content, value => {
    if (isPlainObject(value)) {
      if (!value.slug) {
        return isContentDataValid(value) ? value : null
      }
      return value
    }
    return value.filter(isContentDataValid)
  })
}
const sortContentData = content => {
  return deepMapObjects(content, value => {
    if (isPlainObject(value)) {
      return value
    }

    return value.sort((a, b) => {
      let results = [
        a.position - b.position,
        Date.parse(b.updatedAt) - Date.parse(a.updatedAt),
      ]

      return results.find(result => !isNaN(result) && result !== 0) || 0
    })
  })
}

const cleanContentData = content => {
  return deepMapKeys(content, camelCase)
}

const parseImageData = content => {
  let isImageObject = value => {
    return isValidUrl(value.url) && /image\/(jpeg|png)/.test(value.mime)
  }

  return deepMapObjects(content, value => {
    if (isPlainObject(value) && isImageObject(value)) {
      let url = new URL(value.url)
      let webpUrl = null

      if (
        url.origin === process.env.GATSBY_CDN_URL &&
        /\.(jpe?g|png)$/i.test(url.pathname)
      ) {
        webpUrl =
          url.origin +
          url.pathname.replace(/\.(jpe?g|png)$/i, '.webp') +
          url.search +
          url.hash
      }

      return { ...value, webpUrl }
    }

    return value
  })
}

const CampaignPage = ({ serverData }) => {
  let [
    campaign,
    headerScript,
    brandHeaders,
    citrineBrandHeaders,
    citrineFooters,
    productServices,
    footerHeaders,
    chatShop,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)
  let { handleMessage } = useCampaignsPageDataLayer()
  const [isException, setIsException] = useState(false)
  const [isExceptionType, setIsExceptionType] = useState('not found page')
  useEffect(() => {
    if (campaign) {
      let start = moment(campaign.startDate).format()
      let end = moment(campaign.endDate).format()
      let now = moment()
      let isBetween = now.isBetween(start, end)
      if (!isBetween) {
        setIsException(true)
        if (now.isAfter(end)) {
          setIsExceptionType('end campaign page')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!campaign) {
    return <NotFoundPage />
  }

  campaign = flow(
    cleanContentData,
    filterContentData,
    sortContentData,
    parseImageData
  )(campaign)

  const renderHeaderByTheme = () => {
    switch (campaign.themePages) {
      case 'True':
        return <Header items={brandHeaders} services={productServices} />
      case 'Citrine':
        return (
          <>
            {citrineBrandHeaders && citrineBrandHeaders.header !== null ? (
              <HeaderCitrine menu={citrineBrandHeaders} />
            ) : (
              <></>
            )}
          </>
        )
      default:
        return <Header items={brandHeaders} services={productServices} />
    }
  }

  const renderFooterByTheme = () => {
    switch (campaign.themePages) {
      case 'True':
        return (
          <>
            {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
          </>
        )
      case 'Citrine':
        return (
          <>{citrineFooters && <FooterSecondary items={citrineFooters} />}</>
        )
      default:
        return (
          <>
            {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
          </>
        )
    }
  }

  const renderExceptionPage = () => {
    switch (isExceptionType) {
      case 'not found page':
        return <NotFoundPage />
      case 'end campaign page':
        return (
          <EndCampaign
            endCampaign={campaign.endCampaign}
            lang={campaign.locale}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <Newrelic />
      {isException ? (
        renderExceptionPage()
      ) : (
        <DataLayerProvider handler={handleMessage}>
          <Seo seo={campaign.seo} script={headerScript.script} />
          {/* Requirement from https://truemoney.atlassian.net/jira/software/c/projects/SWP/boards/2460?modal=detail&selectedIssue=SWP-1578&assignee=633a3dad234d44d406d3b537 */}
          {/* Requirement from https://truemoney.atlassian.net/jira/software/c/projects/SWP/boards/2460?modal=detail&selectedIssue=SWP-1585&assignee=633a3dad234d44d406d3b537 */}
          {campaign.slug === '5gtogetherprepay' ||
          campaign.slug === '5gtogetherpostpaid' ? (
            <Gtm />
          ) : (
            <></>
          )}
          {renderHeaderByTheme()}

          {campaign.componentZone.map(zone => (
            <CampaignZone
              key={`${zone.component}:${zone.id}`}
              name={zone.component}
              data={zone}
              locale={serverData.locale}
              slug={serverData.slug}
            />
          ))}
          {renderFooterByTheme()}
          {chatShop && (
            <ChatShop
              content={chatShop}
              mariPlatform={campaign.mariPlatform}
              mariConfig={mariConfig}
            />
          )}
          <CookiesConsent />
        </DataLayerProvider>
      )}
    </>
  )
}

export default CampaignPage

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      `campaign-pages/${params.slug}`,
      'header-script',
      'brand-headers',
      'citrine-brand-headers',
      'citrine-footers',
      'product-services',
      'footer-headers',
      'chat-and-shop',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale, slug: params.slug },
  }
}
