import { FormattedMessage } from 'react-intl'
import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import { EndCampaignPage } from 'swp-components/lib/components/screen'

import useCampaignsPageDataLayer from '@/hooks/data-layers/use-campaigns'

const EndCampaign = ({ endCampaign, lang }) => {
  let pageEndCampaign = {}
  if (
    endCampaign == null ||
    typeof endCampaign == 'undefined' ||
    endCampaign.status === false
  ) {
    pageEndCampaign = {
      title: <FormattedMessage id="EndCampaignPage.titleText" />,
      description: <FormattedMessage id="EndCampaignPage.descriptionText" />,
      imageDesktop: {
        url: 'https://d20w5ycpzrp7v0.cloudfront.net/images/origin/undraw_super_thank_you_re_f8bo_1_1_d03d61e845.png?60421160.70000005',
      },
      buttonName1: <FormattedMessage id="EndCampaignPage.homeButtonText" />,
      link1: {
        url: `https://true.th${lang === 'en' ? '/en' : ''}`,
        newTab: false,
      },
    }
  } else {
    let btn = endCampaign => {
      if (endCampaign.buttonName) {
        let buttons = {}
        if (endCampaign.buttonName[0] && endCampaign.buttonName[0].status) {
          buttons = {
            buttonName1: endCampaign.buttonName[0]?.name,
            link1: {
              url: endCampaign.buttonName[0]?.url,
              newTab: endCampaign.buttonName[0]?.newTab,
            },
          }
        }
        if (endCampaign.buttonName[1] && endCampaign.buttonName[1].status) {
          buttons = {
            ...buttons,
            buttonName2: endCampaign.buttonName[1]?.name,
            link2: {
              url: endCampaign.buttonName[1]?.url,
              newTab: endCampaign.buttonName[1]?.newTab,
            },
          }
        }
        return buttons
      } else {
        return {}
      }
    }
    pageEndCampaign = {
      title: endCampaign.title,
      description: endCampaign.detail,
      imageDesktop: endCampaign.imageDesktop,
      ...btn(endCampaign),
    }
  }
  let { createScopeProps } = useCampaignsPageDataLayer()
  return (
    <DataLayerScope {...createScopeProps('EndCampaign', {})}>
      <EndCampaignPage page={pageEndCampaign}></EndCampaignPage>
    </DataLayerScope>
  )
}

export default EndCampaign
