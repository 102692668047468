import { css } from '@emotion/react'

const style = {
  tabList: css`
    .nav {
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    .nav-item {
      margin: 0;
      min-width: 120px;
      font-size: inherit;
      line-height: inherit;
    }
  `,
  columnCard: css`
    width: 25% !important;
  `,
  card: active => css`
    ${active === true &&
    `
      ::after {
        opacity: 1;
        border: 2px solid;
        border-image-source: linear-gradient(274.96deg, #FF6B70 -23.58%, #FAB416 100.24%);
        background-image: linear-gradient(274.96deg, #FF6B70 -23.58%, #FAB416 100.24%);
      }
    `}
  `,
  mobileCard: active => css`
    border: none;
    .card-header {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      ${active === true &&
      `
        border: 3px solid rgba(255, 37, 27, 1);
      `}
      .arrow-up {
        box-sizing: border-box;
        height: 5vw;
        width: 5vw;
        border-style: solid;
        border-color: black;
        border-width: 0px 1px 1px 0px;
        transform: rotate(-135deg);
        transition: border-width 150ms ease-in-out;
      }
      .arrow-down {
        box-sizing: border-box;
        height: 5vw;
        width: 5vw;
        border-style: solid;
        border-color: black;
        border-width: 0px 1px 1px 0px;
        transform: rotate(45deg);
        transition: border-width 150ms ease-in-out;
      }
    }
    .card-body {
      padding: 1rem 0rem;
      .container {
        padding: unset;
      }
    }
  `,
  box: css`
    .ck-content h1,
    .ck-content h2,
    .ck-content h3,
    .ck-content h4,
    .ck-content h5,
    .ck-content h6 {
      font-size: var(--fs-xs);
    }

    .ck-content .text-tiny {
      font-size: calc(var(--fs-xss) - 6px);
    }

    .ck-content .text-small {
      font-size: calc(var(--fs-xss) - 2px);
    }

    .ck-content .text-big {
      font-size: var(--fs-sm);
    }

    .ck-content .text-huge {
      font-size: var(--fs-6);
    }
    .ck-editor h1,
    .ck-editor h2,
    .ck-editor h3,
    .ck-editor h4,
    .ck-editor h5,
    .ck-editor h6 {
      font-size: var(--fs-xs);
    }

    .ck-editor .text-tiny {
      font-size: calc(var(--fs-xss) - 6px);
    }

    .ck-editor .text-small {
      font-size: calc(var(--fs-xss) - 2px);
    }

    .ck-editor .text-big {
      font-size: var(--fs-sm);
    }

    .ck-editor .text-huge {
      font-size: var(--fs-6);
    }
  `,
  header: css`
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    text-align: center;
  `,
  mobileTitle: css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
  `,
}

export default style
