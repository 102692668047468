import { Container } from 'swp-components/lib/components/bootstrap'
import { SimpleCard } from 'swp-components/lib/components/faq'

import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

const ServiceInfo = ({ topics, ...props }) => {
  let sliderOptions = generateSliderOptions(520)

  return (
    <div {...props}>
      <Container className="position-relative">
        <Slider options={sliderOptions}>
          {topics.map(topic => (
            <div key={topic.id} css={style.box}>
              <SimpleCard css={style.card} topic={topic} />
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  )
}

export default ServiceInfo
