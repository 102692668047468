import { breakpoints } from 'swp-components/lib/assets/config/style'

const generateSliderOptions = preferWidth => {
  return {
    responsive: [
      {
        breakpoint: '100px',
        slidesPerView: 1.2,
      },
      {
        breakpoint: breakpoints.sm,
        navigation: true,
        slidesPerView: (600 - 36) / (preferWidth + 24),
      },
      {
        breakpoint: breakpoints.md,
        navigation: true,
        slidesPerView: Math.round((720 - 36) / (preferWidth + 24)),
      },
      {
        breakpoint: breakpoints.lg,
        slidesPerView: Math.round((960 - 36) / (preferWidth + 24)),
      },
      {
        breakpoint: breakpoints.xl,
        slidesPerView: Math.round((1140 - 36) / (preferWidth + 24)),
      },
      {
        breakpoint: breakpoints.xxl,
        slidesPerView: Math.round((1320 - 36) / (preferWidth + 24)),
      },
    ],
  }
}

export default generateSliderOptions
