import { Container } from 'swp-components/lib/components/bootstrap'
import { Tabs } from 'swp-components/lib/components/common'
import { DeviceList } from 'swp-components/lib/components/esim'

import Slider from '../slider'
import style from './style'

const EsimDeviceList = ({ deviceLists, ...props }) => {
  let tabs = deviceLists.map(list => ({
    key: list.id,
    title: list.brand,
    pane: <DeviceList devices={list.deviceList} />,
  }))

  return (
    <Container {...props}>
      <Tabs
        tabs={tabs}
        tabItemTag="h3"
        wrapTabList={children => (
          <Slider css={style.tabList}>{children}</Slider>
        )}
      />
    </Container>
  )
}

export default EsimDeviceList
