import { Container } from 'swp-components/lib/components/bootstrap'
import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import { Heading } from 'swp-components/lib/components/section'
import { Video } from 'swp-components/lib/components/video-content'

import useCampaignsPageDataLayer from '@/hooks/data-layers/use-campaigns'

import BottomBanner from '../bottom-banner'
import EsimDeviceList from '../esim-device-list'
import EsimHowTo from '../esim-how-to'
import EsimNewHowTo from '../esim-new-how-to'
import FaqList from '../faq-list'
import HighlightBanner from '../highlight-banner'
import PrivilegeShelf from '../privilege-shelf'
import ServiceInfo from '../service-info'
import ServiceInfoGrid from '../service-info-grid'
import ShortcutMenu from '../shortcut-menu'
import TopBanner from '../top-banner'
import Section from './section'
import style from './style'

const CampaignZone = ({ name, data, locale, slug }) => {
  let section = data.section || {}

  let { createScopeProps } = useCampaignsPageDataLayer()

  if (name === 'campaign-page.benefit-list') {
    let preferWidth = {
      small: 240,
      medium: 280,
      large: 360,
    }[data.cardSize]

    if (typeof preferWidth == 'undefined') {
      preferWidth = 240
    }

    return (
      <DataLayerScope
        {...createScopeProps('BenefitList', {
          section: section,
          pageGroup: slug,
        })}
      >
        <Section section={section}>
          <PrivilegeShelf
            preferWidth={preferWidth}
            privileges={data.benefitDetailList}
          />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'campaign-page.bottom-banner') {
    return (
      <DataLayerScope
        {...createScopeProps('BottomBanner', {
          topBanners: data,
          pageGroup: slug,
        })}
      >
        <BottomBanner banners={[data]} />
      </DataLayerScope>
    )
  }

  if (name === 'campaign-page.description') {
    return (
      <Container className="my-5">
        <Heading variant="underline" tag="h1">
          {data.name}
        </Heading>
        <div className="mt-3" css={style.descWrapper}>
          <div css={style.descHeading}>{data.detail}</div>
        </div>
      </Container>
    )
  }

  if (name === 'campaign-page.faq-list') {
    return (
      <DataLayerScope
        {...createScopeProps('FAQ', {
          section: section,
          pageGroup: slug,
          faqs: data,
        })}
      >
        <Section section={section}>
          <FaqList topics={data} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'campaign-page.service-info-list') {
    return (
      <Section section={section}>
        <ServiceInfoGrid topics={data.serviceInfoList} />
      </Section>
    )
  }

  if (name === 'campaign-page.service-info-list-carousel') {
    return (
      <DataLayerScope
        {...createScopeProps('ServiceInfoList', {
          section: section,
          pageGroup: slug,
        })}
      >
        <Section section={section}>
          <ServiceInfo topics={data.serviceInfoList} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'campaign-page.shortcut-menu-list') {
    section.backgroundColor = 'light-orange'
    return (
      <DataLayerScope
        {...createScopeProps('ShortcutMenu', {
          items: data.shortcutMenuList,
          pageGroup: slug,
        })}
      >
        <Section dense section={section}>
          <ShortcutMenu items={data.shortcutMenuList} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'campaign-page.small-banner-list') {
    return (
      <DataLayerScope
        {...createScopeProps('HighlightBanner', {
          topBanners: data.smallBannerList,
          pageGroup: slug,
        })}
      >
        <Section section={section}>
          <HighlightBanner columns={2} banners={data.smallBannerList} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'campaign-page.top-banner-list') {
    return (
      <DataLayerScope
        {...createScopeProps('TopBanner', {
          topBanners: data.topBannerList,
          pageGroup: slug,
        })}
      >
        <TopBanner banners={data.topBannerList} />
      </DataLayerScope>
    )
  }

  if (name === 'esim-page.device-table') {
    return (
      <DataLayerScope
        {...createScopeProps('DeviceList', {
          DeviceList: data.deviceBrandList,
          pageGroup: slug,
          section: section,
        })}
      >
        <Section section={section} tag="h3">
          <EsimDeviceList deviceLists={data.deviceBrandList} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'esim-page.how-to') {
    return (
      <DataLayerScope
        {...createScopeProps('HowTo', {
          howToList: data.howToOsList,
          pageGroup: slug,
          section: section,
        })}
      >
        <Section section={section}>
          <EsimHowTo howToList={data.howToOsList} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'esim-page.new-how-to') {
    return (
      <DataLayerScope
        {...createScopeProps('NewHowTo', {
          howToList: data.howToOsList,
          pageGroup: slug,
          section: section,
        })}
      >
        <Section section={section}>
          <EsimNewHowTo howToList={data.howToCategories} locale={locale} />
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'video-content.video-embed') {
    data.video = JSON.parse(data.video)
    data.video.html = data.video.rawData.html
    return (
      <DataLayerScope
        {...createScopeProps('VideoCampaign', {
          section: section,
          pageGroup: slug,
          faqs: data,
        })}
      >
        <Section section={section}>
          <div className="col-12 col-md-6 m-auto">
            <Video video={data} />
          </div>
        </Section>
      </DataLayerScope>
    )
  }

  if (name === 'video-content.video-upload') {
    return (
      <DataLayerScope
        {...createScopeProps('VideoCampaign', {
          section: section,
          pageGroup: slug,
          faqs: data,
        })}
      >
        <Section section={section}>
          <div className="col-12 col-md-6 m-auto">
            <Video video={data} />
          </div>
        </Section>
      </DataLayerScope>
    )
  }

  return null
}

export default CampaignZone
