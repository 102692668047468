import { css } from '@emotion/react'

const style = {
  box: css`
    .ck-content h1,
    .ck-content h2,
    .ck-content h3,
    .ck-content h4,
    .ck-content h5,
    .ck-content h6 {
      font-size: var(--fs-xs);
    }

    .ck-content .text-tiny {
      font-size: calc(var(--fs-xss) - 6px);
    }

    .ck-content .text-small {
      font-size: calc(var(--fs-xss) - 2px);
    }

    .ck-content .text-big {
      font-size: var(--fs-sm);
    }

    .ck-content .text-huge {
      font-size: var(--fs-6);
    }

    a.nostyle:link {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
    }
    a.nostyle:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
    }
  `,
  descWrapper: css`
    text-align: center;
  `,
  descHeading: css`
    white-space: pre-line;
    font-size: var(--fs-6);
    font-family: var(--text-normal);
    line-height: 2;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  `,
}

export default style
