import { Helmet } from 'react-helmet'

const Gtm = ({ ...props }) => {
  return (
    <>
      <Helmet>
        <script key="function" id="facebook-pixel-script">
          {`
                !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function()
                {n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)
                f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '985726031892265');fbq('track', 'PageView');
            `}
        </script>
        <noscript key="image" id="facebook-pixel-image">
          {`
                <img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=985726031892265&ev=PageView&noscript=1"/>
            `}
        </noscript>
      </Helmet>
      {/* <Helmet>
        <script
          async
          src={'https://www.googletagmanager.com/gtag/js?id=AW-938654182'}
        ></script>
        <script key="function">
          {`
                window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-938654182');
            `}
        </script>
        <script>
          {` gtag('event', 'conversion', {'send_to': 'AW-938654182/nbZsCJfP9I4YEObzyr8D'}); `}
        </script>
      </Helmet> */}
      <Helmet>
        <script
          async
          src={'https://www.googletagmanager.com/gtag/js?id=AW-859838923'}
        ></script>
        <script key="function">
          {`
                window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-859838923');
            `}
        </script>
      </Helmet>
      {/* <Helmet>
        <script key="function">
          {`
                !function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
                ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
                ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++ )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
                ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;
                e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)}; ttq.load('CFQT0LBC77U840VN6U10'); ttq.page(); }(window, document, 'ttq');
            `}
        </script>
      </Helmet> */}
    </>
  )
}

export default Gtm
