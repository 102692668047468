import { css } from '@emotion/react'

const style = {
  box: css`
    padding: 2px 12px;

    @media (min-width: 768px) {
      padding: 2px 0.5rem;
    }
  `,
  card: css`
    @media (max-width: 767px) {
      width: 100%;
    }
  `,
}

export default style
